import { Button, Container, Typography, useTheme } from '@mui/material'
import TwoColumn from '../core/TwoColumn'
import { NavLink } from 'react-router-dom';
import TransitionHover from '../../components/TransitionHover';

export default function Home() {
    const theme = useTheme();

    const marginValues = {
        xs: theme.spacing(6),
        sm: theme.spacing(8),
        md: theme.spacing(10),
        lg: theme.spacing(10),
        xl: theme.spacing(10),
    };

    return (
        <Container sx={{ marginY: marginValues }} >
            <Typography variant='h3' textAlign={'center'} padding={4} >COLECCIONES</Typography>
            <TwoColumn>
                <div>
                    <NavLink to="/vestidos" sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} >
                        <TransitionHover
                            imageUrl='../Vestido.avif'
                            loading="lazy"
                        />
                    </NavLink>
                </div>
                <div>
                    <NavLink to="/complementos" sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }}>
                    <TransitionHover
                            imageUrl='../Complementos.avif'
                            loading="lazy"
                        />
                    </NavLink>
                </div>
            </TwoColumn>
        </Container>
    )
}