import { Suspense } from "react";
import { createBrowserRouter } from "react-router-dom";
import AppLayout from "../layouts/AppLayout";
import Home from "../pages/home/Home";
import Vestidos from '../pages/vestidos/Vestidos';
import Complementos from '../pages/complementos/Complementos';
import Contact from '../pages/contacto/Contact';
import Products from '../pages/rebajados/Products';
import New from '../pages/nuevo/New';
import Tarjeta from '../pages/tarjeta/Tarjeta';
import Login from '../pages/login/Login';
import Cesta from '../pages/cesta/Cesta';
import Fav from '../pages/fav/Fav';
import Search from '../pages/search/Search';
import NotFound from '../pages/not-found/NotFound';
import Signup from "../routes/Signup";

export const RoutesPath = {
  HOME: '/',
  CONTACT: '/contact',
  VESTIDOS: '/vestidos',
  COMPLEMENTS: '/complementos',
  PRODUCT: '/product',
  NEW: '/new',
  TARJETA: '/tarjeta',
  LOGIN: '/login',
  SIGNUP: '/signup',
  CESTA: '/cesta',
  FAV: '/fav',
  SEARCH: '/search'
}

export const AppRouter = createBrowserRouter([
  {
    path: RoutesPath.HOME,
    element: <Suspense><AppLayout behaviour='white' /></Suspense>,
    children: [
      {
        index: true,
        element: <Home />
      },
    ]
  },
  {
    path: RoutesPath.CONTACT,
    element: <Suspense><AppLayout behaviour='default' /></Suspense>,
    children: [
      {
        index: true,
        element: <Contact />
      },
    ]
  },
  {
    path: RoutesPath.VESTIDOS,
    element: <Suspense><AppLayout behaviour='default' /></Suspense>,
    children: [
      {
        index: true,
        element: <Vestidos />
      },
    ]
  },
  {
    path: RoutesPath.COMPLEMENTS,
    element: <Suspense><AppLayout behaviour='default' /></Suspense>,
    children: [
      {
        index: true,
        element: <Complementos />
      },
    ]
  },
  {
    path: RoutesPath.PRODUCT,
    element: <Suspense><AppLayout behaviour='default' /></Suspense>,
    children: [
      {
        index: true,
        element: <Products />
      },
    ]
  },
  {
    path: RoutesPath.NEW,
    element: <Suspense><AppLayout behaviour='default' /></Suspense>,
    children: [
      {
        index: true,
        element: <New />
      },
    ]
  },
  {
    path: RoutesPath.TARJETA,
    element: <Suspense><AppLayout behaviour='default' /></Suspense>,
    children: [
      {
        index: true,
        element: <Tarjeta />
      },
    ]
  },
  {
    path: RoutesPath.LOGIN,
    element: <Suspense><AppLayout behaviour='default' /></Suspense>,
    children: [
      {
        index: true,
        element: <Login />
      },
    ]
  },
  {
    path: RoutesPath.SIGNUP,
    element: <Suspense><AppLayout behaviour='default' /></Suspense>,
    children: [
      {
        index: true,
        element: <Signup />
      },
    ]
  },
  {
    path: RoutesPath.CESTA,
    element: <Suspense><AppLayout behaviour='default' /></Suspense>,
    children: [
      {
        index: true,
        element: <Cesta />
      },
    ]
  },
  {
    path: RoutesPath.FAV,
    element: <Suspense><AppLayout behaviour='default' /></Suspense>,
    children: [
      {
        index: true,
        element: <Fav />
      },
    ]
  },
  {
    path: RoutesPath.SEARCH,
    element: <Suspense><AppLayout behaviour='default' /></Suspense>,
    children: [
      {
        index: true,
        element: <Search />
      },
    ]
  },
  {
    path: '*',
    element: <NotFound />
  }
])

console.log('AppRouter: ', AppRouter);