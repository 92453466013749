import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

const Container = styled(Paper)({
    position: 'relative',
    overflow: 'hidden',
    display: 'inline-block',
    borderRadius: '25px',
});

const Image = styled('img')({
    display: 'block',
    width: '100%',
    height: 'auto',
    transition: 'transform 0.3s ease',
});

const TransitionHover = ({ imageUrl, description }) => {
  const [hovered, setHovered] = useState(false);

  return (
      <Container
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
      >
          <Image
              src={imageUrl}
              style={{ transform: hovered ? 'scale(1.1)' : 'scale(1)' }}
              alt="Image Description"
          />
      </Container>
  );
};


export default TransitionHover; 


