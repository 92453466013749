import { useTheme } from "@emotion/react";
import { Container, Typography } from "@mui/material";

export default function Contact() {
    const theme = useTheme();

    const marginValues = {
        xs: theme.spacing(6),
        sm: theme.spacing(8),
        md: theme.spacing(10),
        lg: theme.spacing(10),
        xl: theme.spacing(10),
    };
    return (
        <Container sx={{ marginY: marginValues }}>
            <Typography textAlign='center' variant="h3">Contacto</Typography>
            <Container>
            </Container>
        </Container>
    )
};