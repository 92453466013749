import { Container, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import FavoriteIcon from '@mui/icons-material/Favorite';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import { NavLink } from 'react-router-dom';

const InformationBar = () => {
    return (
        <Container>
            <Typography
                style={{ color: 'black' }}
                textAlign={'end'}
            >
                <NavLink to='/fav' >
                    <FavoriteIcon />
                </NavLink>
                <NavLink to='/signup' >
                    <PermIdentityIcon />
                </NavLink>
                <NavLink to='/search' >
                    <SearchIcon />
                </NavLink>
                <NavLink to='/cesta' >
                    <WorkOutlineIcon />
                </NavLink>
            </Typography>
        </Container>
    )
}
export default InformationBar;