import { Button, Container, Typography } from "@mui/material";
import SixColumn from "../core/SixColumns";
import { useState } from "react";
import { useTheme } from "@emotion/react";

export default function Vestidos() {
    const [tipoVestido, setTipoVestido] = useState('todos');

    const vestidos = [
        { id: 1, tipo: 'corto', nombre: 'Vestido Corto 1', imagen: '../img/corto1.png' },
        { id: 2, tipo: 'corto', nombre: 'Vestido Corto 2', imagen: '../img/corto2.png' },
        { id: 3, tipo: 'corto', nombre: 'Vestido Corto 3', imagen: '../img/corto3.png' },
        { id: 4, tipo: 'largo', nombre: 'Vestido Largo 1', imagen: '../img/largo1.png' },
        { id: 5, tipo: 'largo', nombre: 'Vestido Largo 2', imagen: '../img/largo2.png' },
        { id: 6, tipo: 'largo', nombre: 'Vestido Largo 3', imagen: '../img/largo3.png' },

    ];

    const filtrarVestidos = (tipo) => {
        setTipoVestido(tipo);
    };

    const vestidosFiltrados = tipoVestido === 'todos' ? vestidos : vestidos.filter(v => v.tipo === tipoVestido);
    
    const theme = useTheme();

    const marginValues = {
        xs: theme.spacing(6),
        sm: theme.spacing(8),
        md: theme.spacing(10),
        lg: theme.spacing(10),
        xl: theme.spacing(10),
    };

    return (
        <Container sx={{ marginY: marginValues }}>
            <Typography textAlign='center' variant="h3">Vestidos</Typography>
            <Container>
                <div className="App">
                    <Button
                        onClick={() => filtrarVestidos('todos')}
                        variant={tipoVestido === 'todos' ? 'contained' : 'outlined'}
                        color="inherit"
                    >
                        Todos
                    </Button>
                    <Button
                        onClick={() => filtrarVestidos('corto')}
                        variant={tipoVestido === 'corto' ? 'contained' : 'outlined'}
                        color="inherit"
                    >
                        Vestido Corto
                    </Button>
                    <Button
                        onClick={() => filtrarVestidos('largo')}
                        variant={tipoVestido === 'largo' ? 'contained' : 'outlined'}
                        color="inherit"
                    >
                        Vestido Largo
                    </Button>
                    <SixColumn>
                        {vestidosFiltrados.map(vestido => (
                            <div key={vestido.id} className={`vestido ${vestido.tipo}`}>
                                <img
                                    alt={vestido.nombre}
                                    src={vestido.imagen}
                                    width={800}
                                    height={1172}
                                    style={{
                                        height: 'auto',
                                        maxWidth: '100%',
                                        aspectRatio: '800 / 1172',
                                        borderRadius: '15px'
                                    }}
                                />
                                <div>{vestido.nombre}</div>
                            </div>
                        ))}
                    </SixColumn>
                </div>
            </Container>
        </Container >

    )
};