import * as React from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import { Button, Container } from '@mui/material';
import { NavLink } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';
import InformationBar from './InformationBar';


const pages = [
    { name: 'Vestidos', link: '/vestidos' },
    { name: 'Complementos', link: '/complementos' },
    { name: 'Productos rebajados', link: '/product' },
    { name: 'NUEVO', link: '/new' },
    { name: 'Tarjeta Regalo', link: '/tarjeta' },
    { name: 'Contacto', link: '/contact' },
];


export default function NavigationBar() {

    const [anchorElNav, setAnchorElNav] = React.useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };
    return (
        <Box position="static" padding={2}>
            <Container maxWidth="xl" >
                <Toolbar disableGutters >
                    <NavLink to="/" sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }}>
                        <img
                            src={'./LogoKrebel.jpeg'}
                            width={'auto'}
                            height={'70'}
                            style={{ margin: '10px', borderRadius: '10px' }}
                            alt="Logo"
                        />
                    </NavLink>
                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                        >
                            {pages.map((page) => (
                                <MenuItem key={page.name} onClick={handleCloseNavMenu}>
                                    <Typography component={NavLink} to={page.link} textAlign="center" variant="body1" color="inherit" sx={{ textDecoration: 'none' }}>
                                        {page.name}
                                    </Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }} >
                        {pages.map((page) => (
                            <Button
                                key={page.name}
                                component={NavLink}
                                to={page.link}
                                onClick={handleCloseNavMenu}
                                sx={{ 
                                    my: 2, 
                                    color: 'black', 
                                    display: 'block', 
                                    textDecoration: 'none',
                                    marginLeft: '50px'
                                }}
                            >
                                {page.name}
                            </Button>
                        ))}
                    </Box>
                    <InformationBar />
                </Toolbar>
            </Container>
        </Box>
    )
}
