import { Link } from "react-router-dom";

 function NotFound() {
    return (
        <div >
            <img src="../img/notfound.png" />
            <div>
                <Link to="/">Home</Link>
            </div>
        </div>
    )
}
export default NotFound;

