import { useTheme } from "@emotion/react";
import { Box, Button, Container, Input, Typography } from "@mui/material";

export default function Search() {
    const theme = useTheme();

    const marginValues = {
        xs: theme.spacing(6),
        sm: theme.spacing(8),
        md: theme.spacing(10),
        lg: theme.spacing(10),
        xl: theme.spacing(10),
    };
    return (
        <Container sx={{ marginY: marginValues }}>
            <Typography textAlign='center' variant="h3">Búsqueda</Typography>
            <Box textAlign='center' margin={2}>
                <Input placeholder="Search" />
                <Button sx={{
                    my: 2,
                    color: 'grey',
                    textDecoration: 'none',
                    textAlign: 'center',
                    border: '2px solid grey'
                }}>Search</Button>
            </Box>
        </Container>
    )
};