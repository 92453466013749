import { NavLink } from "react-router-dom";
import { Container, Divider, Grid, List, ListItem, Typography, Link } from "@mui/material";
import { Email, LocationOn, PhoneInTalk, Schedule } from "@mui/icons-material";
import { ThemeContext } from "../../services/theme";
import { useContext } from "react";


function Footer() {
    const themeMode = useContext(ThemeContext)
    const address = 'Le Bernard, 33220 Margueron, France';
    const FooterTextColor = themeMode.theme === "dark" ? 'white' : 'black';

    return (
        <footer>

            <Container sx={{ marginTop: '300px', marginBottom: '12px' }}>
            <Divider />
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={8} textAlign='left'>
                    <NavLink to="/" sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }}>
                    <img
                        src={'./LogoKrebel.jpeg'}
                        width={'auto'}
                        height={60}
                        style={{ margin: '10px', borderRadius: '10px' }}
                        alt="Logo"
                    />
                </NavLink>
                        <List>
                            <ListItem button key="Address">
                                <Link href="https://maps.app.goo.gl/PRcqdjyQUji9qWCd8" underline="none" target="_blank" color={FooterTextColor}>
                                    <Typography sx={{ display: 'flex' }}>
                                        <LocationOn sx={{ marginRight: '4px' }} />
                                        Mataró
                                    </Typography>
                                </Link>
                            </ListItem>
                            <ListItem key="Schedule">
                                <Typography sx={{ display: 'flex' }}>
                                    <Schedule sx={{ marginRight: '4px' }} />
                                    Horario
                                </Typography>
                            </ListItem>
                            <ListItem button key="Phone">
                                <Link href="tel:+34636636636" underline="none" color={FooterTextColor}>
                                    <Typography sx={{ display: 'flex' }}>
                                        <PhoneInTalk sx={{ marginRight: '4px' }} />
                                        +34 636 636 636
                                    </Typography>
                                </Link>
                            </ListItem>
                            <ListItem button key="Email">
                                <Link href="mailto:info@celler.com" underline="none" color={FooterTextColor}>
                                    <Typography sx={{ display: 'flex' }}>
                                        <Email sx={{ marginRight: '4px' }} />
                                        info@krebel.com
                                    </Typography>
                                </Link>
                            </ListItem>
                        </List>
                    </Grid>
                </Grid>
                <Grid sx={{ marginTop: '12px', display: 'flex', gap: '10px' }} justifyContent='start'>
                </Grid>
            </Container>
        </footer>
    );
}

export default Footer;