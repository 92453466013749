import { useState } from "react";
import { useAuth } from "../auth/AuthProvider";
import { Container, Typography, useTheme } from "@mui/material";


export default function Signup() {
    const theme = useTheme();

    const marginValues = {
        xs: theme.spacing(6),
        sm: theme.spacing(8),
        md: theme.spacing(10),
        lg: theme.spacing(10),
        xl: theme.spacing(10),
    };

    const [name, setName] = useState("");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");


    function handleSubmit (e) {
        e.preventDefault()
        const data = {
            username: username,
            password: password
        };

        fetch('http://localhost:3000/login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .then(result => {
                console.log(result)
            })
            .catch(error =>{
                console.log(error)
                })
    }
    

    

    return (
        <Container sx={{ marginY: marginValues }} >
            <Typography textAlign='center' variant="h3" padding={2}>Signup</Typography>
            <form className="formulario" >
                <label>Name</label>
                <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />

                <label>Username</label>
                <input
                    type="text"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                />

                <label>Password</label>
                <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />

                <button onClick={handleSubmit}>Create user</button>
            </form>
        </Container>
    )


}