import { Outlet } from "react-router-dom";
import NavigationBar from "../pages/core/NavigationBar";
import Footer from "../pages/core/Footer";


export default function AppLayout() {
    return (
        <div>
            <NavigationBar />
            <Outlet />
            <Footer />
        </div>
    )
}