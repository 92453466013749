import { Container, Typography, useTheme } from "@mui/material";
import TwoColumn from "../core/TwoColumn";

export default function Complementos() {
    const theme = useTheme();

    const marginValues = {
        xs: theme.spacing(6),
        sm: theme.spacing(8),
        md: theme.spacing(10),
        lg: theme.spacing(10),
        xl: theme.spacing(10),
    };
    return (
        <Container sx={{ marginY: marginValues }}>
            <Typography textAlign='center' variant="h3">Complementos</Typography>
            <Container>
                <TwoColumn>
                    
                </TwoColumn>
            </Container>
        </Container>
    )
};