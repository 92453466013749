import React, { useState } from "react";
import { useTheme } from "@emotion/react";
import { Container, Typography } from "@mui/material";
import "./login.css";
import { useAuth } from "../../auth/AuthProvider";
import { useNavigate } from "react-router-dom";

export default function Login() {
    const theme = useTheme();

    const marginValues = {
        xs: theme.spacing(6),
        sm: theme.spacing(8),
        md: theme.spacing(10),
        lg: theme.spacing(10),
        xl: theme.spacing(10),
    };

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [errorResponse, setErrorResponse] = useState("")
    const auth = useAuth();
    const goTo = useNavigate()

    function handleSubmit (e) {
        e.preventDefault()
        console.log({
            username: username,
            password: password
        })
    }



    return (
        <Container sx={{ marginY: marginValues }} >
            <Typography textAlign='center' variant="h3" padding={2}>Login</Typography>
            <form className="formulario" onSubmit={handleSubmit}>
                <input
                    type="text"
                    name="username"
                    placeholder="username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                />
                <input
                    type="password"
                    name="password"
                    placeholder="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <button>Iniciar sesion</button>
            </form>

        </Container>
    )
};